import { HttpStatusCode } from 'axios'
import { ErrorAPI } from '../api'

type StartFn<T> = (callback: () => Promise<T>, stopPredicate?: (data: T) => boolean) => Promise<T>

type Config = {
  delay?: number
  attempts?: number
}

const defaultConfig = {
  delay: 2000,
  attempts: 10,
}

export const createPollingManager = <T>(config?: Config) => {
  const { delay = defaultConfig.delay, attempts = defaultConfig.attempts } = config ?? {}

  const start: StartFn<T> = (callback, stopPredicate) => {
    let attempt = 1
    return new Promise<T>((resolve, reject) => {
      // eslint-disable-next-line no-console
      console.log('PollingManager start')
      const poll = async () => {
        // eslint-disable-next-line no-console
        console.log('PollingManager attempt', attempt)
        try {
          const data = await callback()

          if (stopPredicate?.(data)) {
            resolve(data)
            return
          }

          if (attempt < attempts) {
            setTimeout(poll, delay)
            attempt += 1
            return
          }

          reject(
            new ErrorAPI({
              status: HttpStatusCode.RequestTimeout,
              detail: 'Server not responding',
            }),
          )
        } catch (error) {
          reject(error)
        }
      }
      poll()
    })
  }

  return { start }
}
