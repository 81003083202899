export const EXTERNAL_URLS = {
  SIGN_UP: process.env.GATSBY_SIGN_UP_URL!,
  SELLER_PORTAL: process.env.GATSBY_SELLER_PORTAL_URL!,
  HUBSPOT_SCHEDULE_MEETING_LINK: process.env.GATSBY_HUBSPOT_SCHEDULE_MEETING_LINK,
  PRICING: process.env.GATSBY_PRICING_URL,
}

export const INTERNAL_ROUTES = {
  HOME: '/',
  PRICING: '/pricing',
  CONTACT_US: '/contact-us',
}
