import { useEffect } from 'react'
import { checkIsProduction } from './checkIsProduction'

export const useConnectHubspotScript = () => {
  const isProduction = checkIsProduction()

  useEffect(() => {
    if (!isProduction) return

    const script = document.createElement('script')
    script.src = 'https://js.hs-scripts.com/45680332.js'
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    document.head.appendChild(script)
  }, [isProduction])
}
