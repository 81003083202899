export type SentryConfig = {
  enabled: boolean
  dsn: string
  tracesSampleRate?: number | string
  environment?: string
  release?: string
}
export const SENTRY_DEFAULT_TRACE_RATE = 0.2
export const SENTRY_DEFAULT_ENVIRONMENT = 'local'
export const SENTRY_DEFAULT_RELEASE_NAME = 'unknown'
